// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".highlight_V09s6 {\n  font-size: 16px;\n  font-weight: 700;\n  color: #000;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": "highlight_V09s6"
};
module.exports = ___CSS_LOADER_EXPORT___;
