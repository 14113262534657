var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"data-nosnippet":""}},[_c('VanPopup',{staticClass:"logout-popup",style:({ height: 'auto' }),attrs:{"value":_vm.visible,"position":"bottom","round":"","close-on-click-overlay":true,"show-confirm-button":false,"closeable":true},on:{"click-close-icon":_vm.handleCancel,"click-overlay":_vm.handleCancel}},[(_vm.currentContent === _vm.PopupContentType.Action)?_c('div',{staticClass:"action-list-container",attrs:{"data-nosnippet":""}},_vm._l((_vm.logoutActionList),function(action,index){return _c('div',{key:action.key},[(!action.hidden)?[_c('div',{staticClass:"action-item",on:{"click":action.onClick}},[(!!action.iconSrc)?_c('img',{staticClass:"icon",attrs:{"src":action.iconSrc}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(action.desc))])]),_vm._v(" "),(index + 1 !== _vm.logoutActionList.length)?_c('div',{staticClass:"cut-line"}):_vm._e()]:_vm._e()],2)}),0):(_vm.currentContent === _vm.PopupContentType.DeleteTip)?_c('div',{staticClass:"delete-account-tip-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('k_1218479')))]),_vm._v(" "),_c('div',{staticClass:"user-info mt-16"},[(!!_vm.userInfo?.headurl)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.userInfo?.headurl}}):_vm._e(),_vm._v(" "),(!!_vm.userInfo?.user_name)?_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.userInfo?.user_name || ''))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"sub-title mt-16"},[_vm._v(_vm._s(_vm.$t('k_1218486')))]),_vm._v(" "),_c('div',{staticClass:"mt-16"},[_c('div',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('k_1218967', {
                            deactivate_days: `<span class='${_vm.$style.highlight}'>${_vm.DeactivateDays}</span>`,
                            days_unit: `<span class='${_vm.$style.highlight}'>${_vm.$t('k_1218654')}</span>`,
                            app_name: _vm.appName,
                        })
                    )}})]),_vm._v(" "),_c('div',{staticClass:"mt-16"},[_vm._v(_vm._s(_vm.$t('k_1218488')))]),_vm._v(" "),_c('div',{staticClass:"mt-16"},[_c('ul',{staticClass:"delect-warning-message-contianer"},_vm._l(([_vm.$t('k_1218968', { app_name: _vm.appName }), _vm.$t('k_1218490'), _vm.$t('k_1218969', { app_name: _vm.appName })]),function(item,index){return _c('li',{key:`${item}${index}`,style:(`margin-top: ${index !== 0 ? '8px' : '0px'}`)},[_vm._v(_vm._s(item))])}),0)]),_vm._v(" "),_c('div',{staticClass:"mt-16"},[_vm._v(_vm._s(_vm.$t('k_1218492')))]),_vm._v(" "),_c('div',{staticClass:"button-list mt-32"},[_c('div',{staticClass:"btn btn-highlight button-flex mr-16",on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.$t('k_1218483')))]),_vm._v(" "),_c('div',{staticClass:"btn button-flex",on:{"click":_vm.handleNext}},[_vm._v(_vm._s(_vm.$t('k_1218484')))])])]):_c('div',{staticClass:"delete-confirm-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('k_1218479')))]),_vm._v(" "),_c('div',{staticClass:"user-info mt-16"},[(!!_vm.userInfo?.headurl)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.userInfo?.headurl,"alt":""}}):_vm._e(),_vm._v(" "),(!!_vm.userInfo?.user_name)?_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.userInfo?.user_name || ''))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mt-16 desc"},[_vm._v(_vm._s(_vm.$t('k_1218493')))]),_vm._v(" "),_c('div',{staticClass:"btn btn-highlight mt-32",on:{"click":_vm.handleDeleteAccount}},[_vm._v(_vm._s(_vm.$t('k_1218485')))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }