
import { defineComponent, SetupContext, getCurrentInstance, onMounted, reactive, toRefs, watch, computed } from 'vue';
import Popup from 'vant/lib/popup';
import Toast from 'vant/lib/toast';
import { RadarLogData, radarLog, sendLog } from '~/common/radar';
import { UserApi } from '@/api/user';
import { removeLoginCookie } from '~/common/login';

enum LogoutType {
    DeleteAccout = 1,
    Logout = 2,
    ECommerceOrderList = 3,
    TermsOfService = 4,
    PlatformGurantee = 5,
}

export enum PopupContentType {
    Action = 1,
    DeleteTip = 2,
    DeleteConfirm = 3,
}

interface LogoutActionType {
    key?: string | number;
    desc?: string;
    iconSrc?: string;
    onClick?: () => Promise<void> | void;
    hidden?: boolean;
}

interface UserInfo {
    headurl?: string;
    user_name?: string;
}

interface ResponseBase {
    result?: number;
    error_msg?: string;
}

// api 层后续统一治理
interface UserInfoRes extends ResponseBase {
    user?: UserInfo;
}

interface LogoutPopupState {
    currentContent: PopupContentType;
    userInfo?: UserInfo;
}

const DeactivateDays = 30;

const LogoutApiMap: Record<LogoutType, string | undefined> = {
    [LogoutType.Logout]: '/rest/o/w/account/logout',
    [LogoutType.DeleteAccout]: '/rest/o/w/account/delete/do',
    [LogoutType.ECommerceOrderList]: undefined,
    [LogoutType.TermsOfService]: undefined,
    [LogoutType.PlatformGurantee]: undefined,
};

const InitialState: LogoutPopupState = {
    currentContent: PopupContentType.Action,
    userInfo: undefined,
};

const IconMap: Record<LogoutType, string | undefined> = {
    [LogoutType.Logout]: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/logout/logout-icon.png',
    [LogoutType.DeleteAccout]: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/logout/delete-icon.png',
    [LogoutType.ECommerceOrderList]:
        'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/logout/e_commerce_order_list_icon.png',
    [LogoutType.TermsOfService]: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/logout/terms_of_service.png',
    [LogoutType.PlatformGurantee]: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/logout/platform_gurantee.png',
};

export default defineComponent({
    components: {
        VanPopup: Popup,
    },
    props: {
        visible: Boolean,
        initialCurrentContent: {
            type: Number,
            default: PopupContentType.Action,
        },
        showECommerceOrderList: {
            type: Boolean,
            default: false,
        },
        // "小红书" 环境
        isTopick: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['onCancel', 'onLogout', 'onDelete', 'onClickECommerceOrderList'],
    setup(props, ctx: SetupContext<Array<'onCancel' | 'onDelete' | 'onLogout' | 'onClickECommerceOrderList'>>) {
        const { $axios, $root, $store } = getCurrentInstance()?.proxy || {};

        const state = reactive<LogoutPopupState>({ ...InitialState, currentContent: props?.initialCurrentContent || InitialState.currentContent });

        const appName = computed(() => {
            const name = props?.isTopick ? 'Topick' : (getCurrentInstance()?.proxy as { $appName?: string })?.$appName;
            return name;
        });

        const resetCurrentState = () => {
            state.currentContent = props?.initialCurrentContent || InitialState.currentContent;
        };

        const updateUserInfo = async () => {
            const res = await UserApi.getUserInfo({});

            if (res?.result === 1) {
                state.userInfo = res?.user;
                return;
            }

            state.userInfo = undefined;
        };

        const radarEventHandler = (radarData: RadarLogData) => {
            radarLog({
                event_type: 'click',
                category: 'KWAI_SHARE',
                src: window.location.pathname,
                ...radarData,
            });
        };

        const handleCancel = async () => {
            ctx.emit('onCancel');
            resetCurrentState();
            radarEventHandler({ name: 'PWA_LOGOUT_CANCEL' });
        };

        const handleLogout = async () => {
            const res = await $axios?.$$get?.<ResponseBase>(LogoutApiMap[LogoutType.Logout] || '', {});

            if (res?.result !== 1) {
                res?.error_msg &&
                    Toast({
                        message: res.error_msg,
                        duration: 2500,
                    });
                radarEventHandler({
                    name: 'PWA_LOGOUT_FAILURE',
                });
                return;
            }
            Toast({
                message: $root?.$t?.('k_1218965'),
                duration: 2500,
            });
            $store?.commit?.('setIsLogin', false);
            state.userInfo = undefined;
            resetCurrentState();
            removeLoginCookie();
            ctx.emit('onLogout', 'logout');
            radarLog({
                name: 'PWA_LOGOUT_SUCCESSFULLY',
            });
        };

        const handleDeleteAccount = async () => {
            sendLog({
                type: 'click',
                name: 'DELETE_ACCOUNT',
            });

            const res = await $axios?.$$post?.<ResponseBase>(LogoutApiMap[LogoutType.DeleteAccout] || '', {
                reasonId: 0, // 后端 required
                extraInfo: '',
            });

            if (res?.result !== 1) {
                res?.error_msg &&
                    Toast({
                        message: res.error_msg,
                        duration: 2500,
                    });
                radarLog({
                    name: 'PWA_DELETE_ACCOUNT_FAILURE',
                });
                return;
            }

            Toast({
                message: $root?.$t?.('k_1218966'),
                duration: 2500,
            });
            state.userInfo = undefined;
            $store?.commit?.('setIsLogin', false);
            resetCurrentState();
            removeLoginCookie();
            ctx.emit('onDelete', 'delete_account');
            radarLog({
                name: 'PWA_DELETE_ACCOUNT_SUCCESSFULLY',
            });
        };

        const handleViewECommerceOrderList = () => {
            ctx.emit('onClickECommerceOrderList');
        };

        const handleNext = () => {
            const { currentContent } = state;
            state.currentContent = Math.min(currentContent + 1, PopupContentType.DeleteConfirm);

            if (currentContent + 1 === PopupContentType.DeleteConfirm) {
                sendLog({
                    type: 'show',
                    name: 'DELETE_ACCOUNT',
                });
            }
        };

        const logoutActionList: LogoutActionType[] = [
            {
                key: LogoutType.TermsOfService,
                desc: $root?.$t?.('toc') as string,
                iconSrc: IconMap[LogoutType.TermsOfService],
                onClick: () => {
                    window.open('https://keda.kwai.com/pages/187/a4a71b60');
                },
                hidden: !props?.showECommerceOrderList,
            },
            {
                key: LogoutType.PlatformGurantee,
                desc: $root?.$t?.('Platform_Guarantee') as string,
                iconSrc: IconMap[LogoutType.PlatformGurantee],
                onClick: () => {
                    window.open('https://keda.kwai.com/pages/187/5859df90');
                },
                hidden: !props?.showECommerceOrderList,
            },
            {
                key: LogoutType.ECommerceOrderList,
                desc: $root?.$t?.('my_orders') as string,
                iconSrc: IconMap[LogoutType.ECommerceOrderList],
                onClick: handleViewECommerceOrderList,
                hidden: !props?.showECommerceOrderList,
            },
            {
                key: LogoutType.DeleteAccout,
                desc: $root?.$t?.('k_1218479') as string,
                iconSrc: IconMap[LogoutType.DeleteAccout],
                onClick: handleNext,
                hidden: false,
            },
            {
                key: LogoutType.Logout,
                desc: $root?.$t?.('k_1218480') as string,
                iconSrc: IconMap[LogoutType.Logout],
                onClick: handleLogout,
                hidden: false,
            },
        ];

        watch(
            () => props.visible,
            async newVal => {
                if (newVal) {
                    await updateUserInfo();
                }
            },
            { immediate: true }
        );

        onMounted(async () => {
            await updateUserInfo();
        });

        return {
            appName,
            logoutActionList,
            PopupContentType,
            DeactivateDays,
            ...toRefs(state),
            handleNext,
            handleDeleteAccount,
            handleLogout,
            handleCancel,
        };
    },
});
